<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="detail.refund_number"
    class="px-5 py-3"
    v-if="detail"
  >
    <detail-info :detail="detail"></detail-info>
    <div
      class="d-flex align-center justify-center"
      style="margin-top: 32px"
      v-if="permissions.includes('DELETE') && detail.allowed_actions.includes('DELETE')"
    >
      <base-delete-button
        :permissions="permissions"
        :id="detail.uuid"
        @deleteAction="deleteAction"
      ></base-delete-button>
    </div>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const DetailInfo = () => import('@/components/refund-management/refund/detail-info.vue');

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  components: {
    DetailInfo,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.refundInitiator.basePath,
    }),
  },
  methods: {
    async deleteAction(uuid) {
      try {
        await this.$store.dispatch('refundInitiator/delete', uuid);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('refundInitiator.delete.successMsg'),
          'success',
        );
        this.$router.back();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
